<template>
  <div class="organisation-selector" :class="{'hidden': onlyOneOrg}">
    <!-- Org -->
    <div
      v-for="(org, i) in organisations"
      :key="i"
      class="org-button"
      @click.prevent="() => selectOrganisation(org)"
      :class="{ selected: isOrganisationSelected(org) }"
    >
      <organisation-thumbnail :tenant-id="getOrgTenantId(org)"
      :org-id="org.id"
       class="org-thumbnail"></organisation-thumbnail>
      {{ getOrganisationDisplayName(org) }}
    </div>
    <!-- / Org -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import OrganisationThumbnail from '../../components/Organisations/OrganisationThumbnail.vue';
import Organisations from "../../mixins/Organisations";
import organisationHelpers from "../../helpers/organisations";
const _ = require('lodash')
export default {
  components: { OrganisationThumbnail },
  mixins: [Organisations],
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
    }),
    ...mapGetters("helpCaller", {
      selectedOrganisation: "selectedOrganisation",
    }),
    onlyOneOrg() {
      return this.organisations.length == 1;
    }
  },
  created() {
      if (this.organisations.length && !this.selectedOrganisation) {
          this.selectOrganisation(
              _.first(this.organisations)
          )
      }
  },
  methods: {
    ...mapActions("helpCaller", {
      selectOrganisation: "selectOrganisation",
    }),

    isOrganisationSelected(org) {
      return org && this.selectedOrganisation
        ? org.id === this.selectedOrganisation.id
        : false;
    },

    getOrgTenantId(org) {
      return organisationHelpers.getOrganisationTenantId(org);
    }
  },
};
</script>
<style scoped lang="scss">
.organisation-selector {
  display: flex;
  flex-wrap: wrap;

  .org-thumbnail {
    display: inline;
    margin-right: 10px;
  }

  &.hidden {
    display: none;
  }

  .org-button {
    margin-right: 10px;
    background: #fff;
    border-radius: 6px;
    padding: 18px 20px;
    cursor: pointer;
    margin-bottom: 10px;

    &.selected {
      // background: rgba(81, 85, 234, 0.1);
      color: #5155ea;
      border: 1px solid #5155ea;
    }
  }
}
</style>